<template>
  <section class="home-banner" v-if="data && locale">
    <swiper :modules="modules" :slides-per-view="1" :effect="'fade'" :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }" navigation :loop="true" :pagination="{ clickable: true }">
      <swiper-slide v-for="(item, index) in data" :key="index">
        <div class="banner-img" v-if="item?.image">
          <img :src="item.image" alt="banner" />
        </div>
        <div class="content-banner" v-if="contentBanner">
          <div class="text_banner">
            <div class="title_sub" v-if='!isAbout'>
              <span
                style="text-shadow: rgb(0 0 0) 2px 2px 13px;white-space: pre-wrap;">{{contentBanner[locale]?.title}}</span>
            </div>
            <div class="title_sub" v-else>
              <span style="text-shadow:rgb(0 0 0) 2px 2px 13px;" v-if="category" v-html="category"></span>
            </div>
            <h1 v-if='!isAbout' v-html="contentBanner[locale]?.content"
              style="text-shadow: rgb(0 0 0) 2px 2px 13px;"></h1>
            <h1 v-else v-html="contentBanner[locale]?.title"
              style="text-shadow:rgb(0 0 0) 2px 2px 13px;">
            </h1>
          </div>
        </div>
      </swiper-slide>
      <div class="arrow-scroll">
        <img @click="scroolElement('info-number')" src="../../assets/img/icons/arrow-right.svg" alt="" />
      </div>
      <div class="cover-bg"></div>
    </swiper>
  </section>
</template>
<script>
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { mapGetters } from 'vuex';
// import "swiper/modules/scrollbar/scrollbar.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: null,
    contentBanner: null,
    isAbout: null,
    category: null,
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation, Pagination, Autoplay, EffectFade],
    };
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
        },
        loop: true,
        effect: "fade",
      },
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    // activeMenuP() {
    //   this.$store.commit("setMenuActive", 6);
    //   this.$store.commit("setSubActive", 0);
    // },
    scroolElement(el) {
      window.scrollTo({
        top: document.getElementById(el).offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
