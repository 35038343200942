<template>
  <div class="tabs">
    <div class="container" v-if="language && Object.keys(language).length > 0">
      <div class="wrapper_tab" v-if="data">
        <button
          :class="[activeTab === index ? 'active' : '','tabLinks']"
          v-for="(item, index) in data"
          :key="index"
          @click="scroolElement(index, item.id)"
        >
          {{ language[item.name][locale] }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    data: null,
    index: null,
    language: null
  },
  data() {
    return {
      activeTab: -1,
    };
  },
  computed: {
    ...mapGetters(["info", "locale"]),
  },
  methods: {
    scroolElement(index, data) {
      // console.log(data);
      // let el = this.$refs[data];
      // el.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start",
      // });
      this.$emit('ageWasUpdated', index)
      let about = ["about0","about1","about2","about3","about4", "about8"];
      this.activeTab = index;
      console.log(about.includes(data));
      window.scrollTo({
        top: !about.includes(data) ? document.getElementById(data).offsetTop - 200 : document.getElementById(data).offsetTop + 700,
        left: 0,
        behavior: "smooth",
      });

    },
  },
};
</script>
