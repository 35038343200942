<template>
  <div class="main-choose bg-gray">
    <BannerTop :data="data" />
    <Tabs :data="dataTabs" :language="language" />
    <div class="container container-choose bg-line">
      <h1
        class="container-choose__title frames-title"
        ref="choose0"
        id="choose0"
        v-if="language && Object.keys(language).length > 0"
      >
        {{ language["other::competitiveadvantage"][locale] }}
      </h1>
      <div class="advantage">
        <div
          class="list-item"
          v-if="language && Object.keys(language).length > 0"
        >
          <div class="item" v-for="(item, index) in dataNumber" :key="index">
            <div class="item__number">{{ item.number }}</div>
            <div class="item__content">
              <h4 class="title" v-html="language[item.title][locale]"></h4>
              <p class="des" v-html="language[item.des][locale]"></p>
            </div>
          </div>
        </div>
      </div>

      <h1
        class="container-choose__title frames-title"
        ref="choose1"
        id="choose1"
        v-if="language && Object.keys(language).length > 0"
      >
        {{ language["menu::criticalposition"][locale] }}
      </h1>
      <div class="overview-address">
        <div class="overview-address__content">
          <!-- <p>
            Vị trí KCN Tây Bắc Hồ Xá nằm liền kề Quốc lộ 1A, dễ dàng kết nối
            giao thương với các khu vực quan trọng như cảng Cửa Việt, cảng Chân
            Mây, cảng Đà Nẵng, sân bay Phú Bài (Huế), sân bay Đồng Hới (Quảng
            Bình) và khu kinh tế Đông Nam. Từ Khu B của KCN Tây Bắc Hồ Xá đến ga
            đường sắt – Ga San Lung chỉ mất 5 phút lái xe (500m).
            <br /><br />
            Đây cũng là vị trí sở hữu nguồn tài nguyên dồi dào, phong phú từ
            rừng, khoáng sản, đất đỏ ba dan, thuỷ/ hải sản, hồ tiêu, cao su,…
          </p>
          <p>
            Vì vậy các dự án đầu tư dễ dàng tiếp cận các nguồn nguyên liệu phục
            vụ hoạt động sản xuất kinh doanh.<br /><br />
            KCN Tây Bắc Hồ Xá nằm trong tỉnh Quảng Trị là điểm khởi đầu và điểm
            kết thúc của Hành lang kinh tế Đông Tây kết nối 4 quốc gia và 13
            tỉnh/thành phố với các thị trường tiềm năng 10,5 triệu dân.
          </p> -->
          <p v-html="data.textLeft" style="white-space: pre-wrap"></p>
          <p v-html="data.textRight" style="white-space: pre-wrap"></p>
        </div>
        <div class="overview-address__bg">
          <img class="pc" src="../assets/images/banner-choose.svg" alt="" />
          <img class="mb" src="../assets/images/img_oto.svg" alt="" />
          <div class="list-car" id="example-anchor">
            <div class="box box1">
              <img src="../assets/img/about/car-blue.svg" alt="" />
            </div>
            <div class="box box2">
              <img src="../assets/img/about/car-blue.svg" alt="" />
            </div>
            <div class="box box3">
              <img src="../assets/img/about/car-blue.svg" alt="" />
            </div>
            <div class="box box4">
              <img src="../assets/img/about/car-yellow.svg" alt="" />
            </div>
            <div class="box box5">
              <img src="../assets/img/about/car-yellow.svg" alt="" />
            </div>
            <div class="box box6">
              <img src="../assets/img/about/car-yellow.svg" alt="" />
            </div>
            <div class="box box7">
              <img src="../assets/img/about/car-yellow.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <h1
        class="container-choose__title frames-title"
        ref="choose2"
        id="choose2"
        v-if="language && Object.keys(language).length > 0"
      >
        {{ language["menu::investmentpolicy"][locale] }}
      </h1>
      <div class="policy">
        <div class="policy__content">
          <div class="list-title" v-if="dataPolicy?.length > 0">
            <div
              v-for="(item, index) in dataPolicy"
              :key="index"
              class="item"
              @click="DetailPolicy(item, index)"
            >
              <p
                :class="[activePolicy == index ? 'active' : '', 'item__title']"
                v-html="item[locale]?.title"
              ></p>
            </div>
          </div>
          <div class="detail" v-if="dataPolicy?.length > 0">
            <p
              v-html="dataPolicy[activePolicy][locale]?.content"
              v-if="activePolicy !== 0"
            ></p>
            <p v-if="activePolicy === 0">
              <img
                :src="dataPolicy[0].image"
                style="width: 100%; object-fit: cover"
              />
            </p>
          </div>
        </div>
      </div>
      <h1
        class="container-choose__title frames-title-why bbb"
        ref="choose3"
        id="choose3"
        v-if="language && Object.keys(language).length > 0"
      >
        {{ language["other::amberinthenorthwest2"][locale] }}
      </h1>
      <div class="develop">
        <p
          class="develop__info"
          style="color: #112047 !important"
          v-html="growth_potential"
        ></p>
        <!-- <p class="develop__info">
          Với sự phát triển đột phá của các dự án hạ tầng và các dự án kinh tế
          trọng điểm của Quảng Trị trong thời gian tới, tiềm năng phát triển của
          Khu công nghiệp Tây Bắc Hồ Xá rất lớn. Nhiều dự án hạ tầng giao thông
          được xây dựng trong thời gian tới
        </p> -->
        <div class="develop__bg">
          <img src="../assets/images/banner-dev.png" alt="" />
        </div>
        <div class="develop__content">
          <div
            class="list-item"
            v-if="language && Object.keys(language).length > 0"
          >
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev1.svg" alt="" />
              </div>
              <div class="item__title">
                <p v-if="language && Object.keys(language).length > 0">
                  {{ language["other::airportprepares"][locale] }}
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev2.svg" alt="" />
              </div>
              <div class="item__title">
                <p>
                  {{ language["other::centermain"][locale] }}
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev3.svg" alt="" />
              </div>
              <div class="item__title">
                <p>
                  <!-- Đường ven biển kết nối hành lang kinh tế Đông - Tây giai đoạn
                  I -->
                  {{ language["other::economiccorridor"][locale] }}
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev4.svg" alt="" />
              </div>
              <div class="item__title">
                <p>
                  <!-- Dự án nâng cấp, mở rộng quốc lộ 9 đoạn từ quốc lộ 1 đến cảng
                  Cửa Việt -->
                  {{ language["other::9sectionfrom"][locale] }}
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev5.svg" alt="" />
              </div>
              <div class="item__title">
                <p>{{ language["other::westbranch"][locale] }}</p>
              </div>
            </div>
            <div class="item">
              <div class="item__avt">
                <img src="../assets/images/dev6.svg" alt="" />
              </div>
              <div class="item__title">
                <p>
                  <!-- Dự án xây dựng đường bộ cao tốc Bắc - Nam phía Đông, giai đoạn
                  2021-2025 cách KCN Amber Tây Bắc Hồ Xá chỉ 10km -->
                  {{ language["other::branchwiththe"][locale] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact :language="language" />
  </div>
</template>

<script>
/* global $ */
// import { mapMutations } from "vuex";
import BannerTop from "@/components/common/BannerTop.vue";
import Tabs from "@/components/common/Tabs.vue";
import Contact from "@/components/home/Contact.vue";
import WhyService from "@/services/WhyService";
import { mapGetters } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    BannerTop,
    Tabs,
    Contact,
  },
  created() {
    this.dataHome();
  },
  data() {
    return {
      language: [],
      growth_potential: "",
      data: {
        category: "ƯU THẾ Amber Tây Bắc Hồ Xá",
        title: "Cùng nhau chúng ta tạo nên <br> một tương lai tốt đẹp hơn",
        des: "",
        images: "",
      },
      dataTabs: [
        {
          name: "menu::competitiveadvantage",
          id: "choose0",
        },
        {
          name: "menu::criticalposition",
          id: "choose1",
        },
        {
          name: "menu::investmentpolicy",
          id: "choose2",
        },
        {
          name: "other::amberinthenorthwest2",
          id: "choose3",
        },
      ],
      dataNumber: [
        {
          number: "01",
          title: "menu::criticalposition",
          des: "other::transportationdue",
        },
        {
          number: "02",
          title: "other::rentalprice",
          des: "other::vietnamandin",
        },
        {
          number: "03",
          title: "other::investmentincentive",
          des: "other::attractivedue",
        },
        {
          number: "04",
          title: "other::infrastructure4",
          des: "other::modernservice2",
        },
        {
          number: "05",
          title: "other::planninglocation",
          des: "other::housingprojects213",
        },
        {
          number: "06",
          title: "other::landarea7",
          des: "other::productionexpansion2",
        },
        {
          number: "07",
          title: "other::laborsource",
          des: "other::abundant6",
        },
        {
          number: "08",
          title: "other::period9",
          des: "other::yearsto2071",
        },
      ],
      dataPolicy: [
        {
          title: "Ưu đãi thuế thu nhập doanh nghiệp",
          content:
            "Được miễn thuế VAT đối với hàng hoá là nguyên liệu nhập khẩu để sản xuất, gia công hàng hoá xuất khẩu theo hợp đồng gia công xuất khẩu ký kết với nước ngoài.",
        },
        {
          title: "Ưu đãi Thuế Giá trị gia tăng",
          content:
            "Được miễn thuế VAT đối với hàng hoá là nguyên liệu nhập khẩu để sản xuất",
        },
        {
          title: "Ưu đãi Thuế nhập khẩu",
          content:
            "Được miễn thuế VAT đối với hàng hoá là nguyên liệu nhập khẩu để sản xuất, gia công hàng hoá xuất khẩu theo hợp đồng gia công xuất khẩu ký kết với nước ngoài.",
        },
        {
          title: "Ưu đãi khác từ tỉnh Quảng Trị",
          content:
            "Được miễn thuế VAT đối với hàng hoá là nguyên liệu nhập khẩu để sản xuất, gia công hàng hoá xuất khẩu theo hợp đồng gia công xuất khẩu ký kết với nước ngoài.",
        },
      ],
      contentPolicy:
        "Được miễn thuế VAT đối với hàng hoá là nguyên liệu nhập khẩu để sản xuất, gia công hàng hoá xuất khẩu theo hợp đồng gia công xuất khẩu ký kết với nước ngoài.",
      activePolicy: 0,
    };
  },
  watch: {
    "$store.state.subActive": function () {
      let ref = "choose" + this.subActive;
      let el = this.$refs[ref];
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.checkScroll("choose");
    this.$nextTick(function () {
      $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 120) {
          $(".overview-address__bg").addClass("active");
        } else {
          $(".overview-address__bg").removeClass("active");
        }
      });
    });
    this.getDetail();
    this.getdataInverstment();
  },
  computed: {
    ...mapGetters(["subActive", "locale"]),
  },
  methods: {
    DetailPolicy(item, index) {
      this.contentPolicy = item.content;
      this.activePolicy = index;
    },
    async dataHome() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data;
          this.data.category = resp.data.data["menu::advantages"][this.locale];
        })
        .catch(() => {});
    },
    checkScroll(elem) {
      if (this.subActive >= 0) {
        let ref = elem + this.subActive;
        let el = this.$refs[ref];
        if (this.subActive >= 0) {
          this.scrollEl(el, "");
        }
      }
    },
    scrollEl(el, pointV) {
      let point = pointV ? pointV : "start";
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: point,
        });
      }
    },
    async getDetail() {
      await WhyService.detailWhyChoose()
        .then((resp) => {
          this.data.title = resp.data.data.banner[this.locale]?.title;
          this.data.images = resp.data.data.banner.image.replaceAll(" ", "%20");
          this.dataSlideA = resp.data.data.imagesBanner;
          this.contentBanner = resp.data.data.banner;
          this.data.textLeft = resp.data.data.banner[this.locale]?.text_left;
          this.data.textRight = resp.data.data.banner[this.locale]?.text_right;
          this.data.content = resp.data.data.banner[this.locale]?.content;

          this.growth_potential =
            resp.data.data.banner[this.locale]?.growth_potential;
          // this.dataProject = this.dataNew.blocks;
        })
        .catch(() => {});
    },
    async getdataInverstment() {
      await WhyService.dataInverstment()
        .then((resp) => {
          console.log(resp.data.data);
          this.dataPolicy = resp.data.data.blocks;
        })
        .catch(() => {});
    },
  },
};
</script>
