<template>
  <section class="infrastructure-cloud">
    <swiper
      v-if="data?.length > 0"
      :breakpoints="swiperOption.breakpoints"
      :modules="modules"
      slidesPerView = 2,
      navigation
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
    >
      <swiper-slide v-for="(item, index) in data" :key="index">
        <div class="item">
          <div class="item__title">{{item[locale]?.title}}</div>
          <div class="item__des" v-html="item[locale]?.content">
          </div>
          <div class="item__bg">
            <img :src="item.image" alt="" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
<script>
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { mapGetters } from 'vuex';
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
    props: {
      data: []
    },
  setup() {
    return {
        modules: [Navigation],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  data() {
    return {
      swiperOption: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1023: {
            slidesPerView: 2,
            spaceBetween: 60,
          }
        },
      },
      // data: [
      //   {
      //     title: "Giao thông nội bộ",
      //     des: "Trục chính 30 - 45m, trục nhánh 13,5 - 19,5 m. Các tuyến giao thông nội bộ quy hoạch theo mạng lưới ô vuông bàn cờ, đảm bảo tiếp cận thuận lợi các khu đất nhà máy",
      //     avt: require("../../assets/images/item1.svg"),
      //   },
      //   {
      //     title: "Nguồn điện",
      //     des: " Điện áp 22kV hạ tại cổng nhà máy, đầu nối từ trạm biến áp 100/22kV- 40MVA.",
      //     avt: require("../../assets/images/item2.svg"),
      //   },
      //   {
      //     title: "Giao thông nội bộ",
      //     des: "Trục chính 30 - 45m, trục nhánh 13,5 - 19,5 m. Các tuyến giao thông nội bộ quy hoạch theo mạng lưới ô vuông bàn cờ, đảm bảo tiếp cận thuận lợi các khu đất nhà máy",
      //     avt: require("../../assets/images/item1.svg"),
      //   },
      // ],
    };
  },
  methods: {},
};
</script>
