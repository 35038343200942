<template>
  <div class="main-about">
    <Banner :data="dataSlideA" :contentBanner="contentBanner" :isAbout="true" :category="category" />
    <Tabs :data="dataTabs" :language="language"/>
    <div class="container container-about bg-line">
      <div class="container-about__content" ref="about0" id="about0">
        <h1 class="container__title" v-html="dataB.content"></h1>
        <div class="container__content about-content">
          <p style="white-space: pre-wrap !imporant;">
            <span v-for="(item, index) in dataB?.textLeft?.split('\r\n')" :key="index" style="display: block;">
              <span  v-if="item.includes(':')">
                <span v-for="(iprodtem, indexPr) in item.split(':')" :key="indexPr">
                  <strong v-if="indexPr === 0" style="font-weight: bold">{{ iprodtem }}:</strong>
                  <span v-if="indexPr !== 0">{{ iprodtem }}</span>
                </span>
              </span>
              <span v-else v-html="item" style="display: block; padding-top: 10px;">
              </span>
              
            </span>
          </p>
          <!-- <p v-html="dataB.textLeft"></p> -->
          <p v-html="dataB.textRight" style="white-space: pre-wrap !imporant;"></p>
        </div>
      </div>
      <div class="container__bg">
        <img src="../assets/images/bg22.png" alt="" />
      </div>
      <div class="container__bg-sub">
        <img src="../assets/images/bg29.png" alt="" />
      </div>
      <div class="container-about__map">
        <img class="mb" src="../assets/images/map_555.svg" alt="" />
        <img class="pc" src="../assets/img/about/map-about.svg" alt="" />
        <!-- <div class="box dat1">
          <img src="../assets/img/about/dat1.svg" alt="" />
        </div>
        <div class="box dat2">
          <img src="../assets/img/about/dat2.svg" alt="" />
        </div> -->
        <div class="box sanbay">
          <img src="../assets/img/about/sanbaydonghoi.svg" alt="" />
          <img class="hover" src="../assets/img/about/SanbayDongHoi.png" alt="" />
        </div>
        <div class="box sanbay box1">
          <img src="../assets/img/about/cangcuaviet.svg" alt="" />
          <img class="hover" src="../assets/img/about/CangCuaViet.png" alt="" />
        </div>
        <div class="box box2 sanbay">
          <img src="../assets/img/about/box1.svg" alt="" />
          <img class="hover" src="../assets/img/about/CangMyThuy.png" alt="" />
        </div>
        <div class="box box3 sanbay">
          <img src="../assets/img/about/box2.svg" alt="" />
          <img class="hover" src="../assets/img/about/SanbayPhuBai.png" alt="" />
        </div>
        <div class="box box4 sanbay">
          <img src="../assets/img/about/box3.svg" alt="" />
          <img class="hover" src="../assets/img/about/Cangchanmay.png" alt="" />
        </div>
        <div class="box box5 sanbay">
          <img src="../assets/img/about/box4.svg" alt="" />
          <img class="hover" src="../assets/img/about/Cangtiensa.png" alt="" />
        </div>
        <div class="box box6 sanbay">
          <img src="../assets/img/about/cuakhaulaobao.svg" alt="" />
          <img class="hover" src="../assets/img/about/Cuakhaulaobao.png" alt="" />
        </div>
        <div class="box box7 sanbay">
          <img src="../assets/img/about/box5.svg" alt="" />
          <img class="hover" src="../assets/img/about/SanbayDaNang.png" alt="" />
        </div>
        <div class="box box8 sanbay">
          <img src="../assets/img/about/box6.svg" alt="" />
          <img class="hover" src="../assets/img/about/Caukhaulalay.png" alt="" />
        </div>
      </div>
      <div class="floor-plan" ref="about1" id="about1" v-if="language && Object.keys(language).length > 0">
        <h1 class="container-about__title frames-title" >{{language["other::Subdivision Plans8"][locale]}}</h1>
        <div class="floor-plan__category">
          <div class="item">
            <div class="item__avt">
              <img src="../assets/images/card1.png" alt="" />
            </div>
            <div class="item__title">{{language["other::FACTORY"][locale]}}</div>
          </div>
          <div class="item">
            <div class="item__avt">
              <img src="../assets/images/card3.png" alt="" />
            </div>
            <div class="item__title">{{language["other::ZONES"][locale]}}</div>
          </div>
          <div class="item">
            <div class="item__avt">
              <img src="../assets/images/card5.png" alt="" />
            </div>
            <div class="item__title">{{language["other::HOUSING"][locale]}}</div>
          </div>
          <div class="item">
            <div class="item__avt">
              <img src="../assets/images/card2.png" alt="" />
            </div>
            <div class="item__title">{{language["other::CENTER"][locale]}}</div>
          </div>
          <div class="item">
            <div class="item__avt">
              <img src="../assets/images/card4.png" alt="" />
            </div>
            <div class="item__title">{{language["other::AREA"][locale]}}</div>
          </div>

        </div>
        <div class="floor-plan__content">
          <div class="title">
            <h1 class="title__plan">{{language["other::AREAa"][locale]}}</h1>
            <h2 class="title__area">116,54 HA</h2>
          </div>
          <div class="bg">
            <img src="../assets/images/bg23.svg" alt="" />
            <div class="list-box" v-if="language && Object.keys(language).length > 0">
              <div class="list-box__content">
                <div class="box box1a tooltip">
                  <p class="box__number">1</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::CONSTRUCTIONWORKER"][locale]'></span>
                  </div>
                </div>
                <div class="box box1b tooltip">
                  <p class="box__number">1</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::CONSTRUCTIONWORKER"][locale]'></span>
                  </div>
                </div>

                <div class="box box2a tooltip">
                  <p class="box__number">2</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::infrastructure201"][locale]'></span>
                  </div>
                </div>
                <div class="box box2b tooltip">
                  <p class="box__number">2</p>
                  <div class="box__content tooltiptext tooltip-top">
                   <span v-html='language["other::infrastructure201"][locale]'></span>
                  </div>
                </div>

                <div class="box box3b tooltip">
                  <p class="box__number">3</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::WELCOME103"][locale]'></span>
                  </div>
                </div>
                <div class="box box3a tooltip">
                  <p class="box__number">3</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::WELCOME104"][locale]'></span>
                  </div>
                </div>
                <div class="box box4a tooltip">
                  <p class="box__number">4</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::CenterforOperations"][locale]'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="floor-plan__content" v-if="language && Object.keys(language).length > 0">
          <div class="bg2" >
            <img src="../assets/images/bandonew.svg" alt="" />
            <div class="list-box">
              <div class="list-box__content">
                <div class="box box1 tooltip">
                  <p class="box__number">1</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::CONSTRUCTIONWORKER"][locale]'></span>
                  </div>
                </div>
                <div class="box box2 tooltip">
                  <p class="box__number">2</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::infrastructure201"][locale]'></span>

                  </div>
                </div>
                <div class="box box5 tooltip">
                  <p class="box__number">3</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span v-html='language["other::WELCOME103"][locale]'></span>

                  </div>
                </div>
                <div class="box box4 tooltip">
                  <p class="box__number">4</p>
                  <div class="box__content tooltiptext tooltip-top">
                    <span>{{language["other::CenterforOperations"][locale]}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <h1 class="title__plan">{{language["other::AREAb"][locale]}}</h1>
            <h2 class="title__area">98,23 HA</h2>
          </div>
        </div>
        <div class="floor-plan__select" v-if="language && Object.keys(language).length > 0">
          <div class="box">
            <div class="box__number">1</div>
            <div class="box__title" v-html='language["other::CONSTRUCTIONWORKER"][locale]'></div>
          </div>
          <div class="box">
            <div class="box__number">2</div>
            <div class="box__title">{{language["other::infrastructure201"][locale]}}</div>
          </div>
          <div class="box">
            <div class="box__number">3</div>
            <div class="box__title">{{language["other::congchao"][locale]}}</div>
          </div>
          <div class="box">
            <div class="box__number">4</div>
            <div class="box__title">{{language["other::CENTER"][locale]}}</div>
          </div>
        </div>
      </div>
      <div class="project-info" ref="about2" id="about2">
        <h1 class="container-about__title frames-title" v-if="language && Object.keys(language).length > 0">{{language["menu::product"][locale]}}</h1>
        <div class="project-info__content">
          <div class="project-left">
            <div class="list-item" v-if="dataProject.length > 0">
              <div :class="[activeProject == index ? 'active' : '', 'item']" v-for="(item, index) in dataProject"
                :key="index" @click="openProject(index)">
                <h2 class="item__title" v-html="item[locale]?.title"></h2>
                <p class="item__des" v-html="item[locale]?.content"></p>
              </div>
            </div>
          </div>
          <div class="project-right" id="about3">
            <img :src="dataProject[activeProject].image" alt="" style="width: 100%; object-fit: cover" />
          </div>
        </div>
      </div>
      <div class="infrastructure" ref="about3" id="about4" v-if="language && Object.keys(language).length > 0">
        <h1 class="container-about__title frames-title">{{language["other::infrastructure"][locale]}}</h1>
        <h2 class="infrastructure__title">{{language["other::Infrastructure"][locale]}}</h2>
        <div class="infrastructure__tech">
          <Cloud :data="dataCloud" />
        </div>
        <h2 class="infrastructure__title">{{language["other::svInfrastructure"][locale]}}</h2>
        <div class="infrastructure__service">
          <div class="item">
            <div class="item__title">{{language["other::OPERATION24"][locale]}}</div>
            <div class="item__bg">
              <img src="../assets/images/service1.svg" alt="" />
              <img src="../assets/images/service1w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">{{language["other::FIGHTING24"][locale]}}</div>
            <div class="item__bg">
              <img src="../assets/images/service2.svg" alt="" />
              <img src="../assets/images/service2w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">
              {{language["other::INTERNATIONAL"][locale]}}
            </div>
            <div class="item__bg">
              <img src="../assets/images/service3.svg" alt="" />
              <img src="../assets/images/service3w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">{{language["other::LOGISTICS"][locale]}}</div>
            <div class="item__bg">
              <img src="../assets/images/service4.svg" alt="" />
              <img src="../assets/images/service4w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">{{language["other::SUPPORTSERVICE"][locale]}}</div>
            <div class="item__bg">
              <img src="../assets/images/service5.svg" alt="" />
              <img src="../assets/images/service5w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">
              <!-- HỖ TRỢ THÔNG TIN, TƯ VẤN MÔI TRƯỜNG KINH DOANH -->
              {{language["other::SUPPORT"][locale]}}
              <!-- {{language["other::INTERNATIONAL"][locale]}} -->
            </div>
            <div class="item__bg">
              <img src="../assets/images/service6.svg" alt="" />
              <img src="../assets/images/service6w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">{{language["other::ORGANIZING"][locale]}}</div>
            <div class="item__bg">
              <img src="../assets/images/service7.svg" alt="" />
              <img src="../assets/images/service7w.svg" class="hover" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item__title">
              <!-- TIỆN ÍCH DỊCH VỤ KHÁC: TRẠM XĂNG, NGÂN HÀNG… -->
              {{language["other::OTHERSERVICE"][locale]}}
              <!-- {{language["other::ORGANIZING"][locale]}} -->
            </div>
            <div class="item__bg">
              <img src="../assets/images/service8.svg" alt="" />
              <img src="../assets/images/service8w.svg" class="hover" alt="" />
            </div>
          </div>
        </div>
        <h2 class="infrastructure__title">{{language["other::SocialInfrastructure"][locale]}}</h2>
        <div class="infrastructure__society">
          <h2 class="title">
            <!-- Khu công nghiệp Amber Tây Bắc Hồ Xá được hỗ trợ bởi hệ thống hạ tầng xã
            hội đồng bộ gồm: -->
            {{language["other::AmberIndustrial"][locale]}}
          </h2>
          <div class="list-item">
            <div class="item">
              <div class="item__bg">
                <img src="../assets/images/society1.svg" alt="" />
              </div>
              <div class="item__title">
                <!-- 2 DỰ ÁN NHÀ Ở CHO CHUYÊN GIA VÀ CÔNG NHÂN -->
                {{language["other::PROJECTSFORPROFESSIONAL"][locale]}}
              </div>
            </div>
            <div class="item">
              <div class="item__bg">
                <img src="../assets/images/society2.svg" alt="" />
              </div>
              <div class="item__title">{{language["other::IN5KMRADIATION"][locale]}}</div>
            </div>
            <div class="item maket">
              <div class="item__bg">
                <img src="../assets/images/society3.svg" alt="" />
              </div>
              <div class="item__title">{{language["other::IN1KMRADIATION"][locale]}}</div>
            </div>
          </div>
          <h4 class="des">
            <!-- Chỉ mất 20 phút di chuyển từ KCN Amber Tây Bắc Hồ Xá đến thành phố Đông
            Hà, thủ phủ của tỉnh Quảng Trị nơi mọi nhu cầu đều được đáp ứng -->
            {{language["other::onlytakes20minutes"][locale]}}
          </h4>
        </div>
      </div>
      <div class="career">
        <h1 class="container-about__title frames-title" ref="about4" id="about8" v-if="language && Object.keys(language).length > 0">
          {{language["menu::attractiveindustry"][locale]}}
        </h1>
        <div class="career__content">
          <div class="career-left" id="about8">
            <div class="list-item" v-if="language && Object.keys(language).length > 0">
              <div :class="[activeCareer == index ? 'active' : '', 'item']" v-for="(item, index) in dataCareer"
                :key="index" @click="setCareer(item, index)">
                <h4 class="item__title" v-html="language[item.title][locale]"></h4>
              </div>
            </div>
          </div>
          <div class="career-right">
            <img :src="imgCareer" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Contact :language="language"/>
  </div>
</template>

<script>
// import { mapMutations } from "vuex";
import Banner from "@/components/home/Banner.vue";
import Tabs from "@/components/common/Tabs.vue";
import Contact from "@/components/home/Contact.vue";
import Cloud from "@/components/common/Cloud.vue";
import AboutService from "@/services/AboutService";
import { mapGetters } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    Banner,
    Tabs,
    Contact,
    Cloud,
  },
  data() {
    return {
      dataB: {},
      dataCloud: [],
      contentBanner: {},
      activeProject: 0,
      pictureProject: require("../assets/images/bg26.png"),
      dataTabs: [
        {
          name: "menu::overview",
          id: "about0",
        },
        {
          name: "menu::subdivisionfloorplan",
          id: "about1",
        },
        {
          name: "menu::product",
          id: "about2",
        },
        {
          name: "menu::infrastructure",
          id: "about4",
        },
        {
          name: "menu::attractiveindustry",
          id: "about8",
        },
      ],
      data: [
        {
          title: "",
          img: require("../assets/img/info/dk.png"),
        },
        {
          title: "",
          img: require("../assets/img/info/dk.png"),
        },
        {
          title: "",
          img: require("../assets/img/info/dk.png"),
        },
      ],
      dataSlideA: [
        {
          info: "TỔNG QUAN KCN",
          title: "Cùng nhau chúng ta tạo nên <br> một tương lai tốt đẹp hơn",
          image: require("../assets/images/banner3.png"),
        },
        {
          info: "TỔNG QUAN KCN",
          title: "Cùng nhau chúng ta tạo nên <br> một tương lai tốt đẹp hơn",
          image: require("../assets/images/banner3.png"),
        },
        {
          info: "TỔNG QUAN KCN",
          title: "Cùng nhau chúng ta tạo nên <br> một tương lai tốt đẹp hơn",
          image: require("../assets/images/banner3.png"),
        },
      ],
      dataProject: [
        {
          title: "Thuê đất & hạ tầng",
          des: " Quỹ đất công nghiệp cho thuê: 158,12 ha <br /> Khu A: 84,25 ha<br /> Khu B: 73,87 ha <br />Thời hạn thuê: 50 năm (đến 2071)",
        },
        {
          title: "Thuê văn phòng",
          des: "Tòa nhà điều hành KCN Amber Tây Bắc Hồ Xá với tổng diện tích khuôn viên lên tới 2 ha, bao gồm không gian văn phòng làm việc và diện tích cảnh quan cây xanh, mặt nước.",
        },
        {
          title: "Nhà xưởng/Kho xây theo yêu cầu",
          des: "Ngoài các sản phẩm cho thuê đất & hạ tầng và cho thuê văn phòng, với đội ngũ chuyên viên giàu kinh nghiệm",
        },
      ],
      dataCareer: [
        {
          title: "other::may",
          image: require("../assets/images/career1.svg"),
        },
        {
          title: "other::Manufacturemetal",
          image: require("../assets/images/career7.svg"),
        },
        {
          title: "other::processing",
          image: require("../assets/images/career2.svg"),
        },
        {
          title: "other::processingproduction",
          image: require("../assets/images/career3.svg"),
        },
        {
          title: "other::Assemblymechanics",
          image: require("../assets/images/career4.svg"),
        },
        {
          title: "other::productionprocessing",
          image: require("../assets/images/career5.svg"),
        },
        {
          title: "other::khobai",
          image: require("../assets/images/career6.svg"),
        },
      ],
      imgCareer: require("../assets/images/career1.svg"),
      activeCareer: 0,
      dataNew: [],
      language: [],
      category: ''
    };
  },
  watch: {
    "$store.state.subActive": function () {
      let ref = "about" + this.subActive;
      let el = this.$refs[ref];
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.checkScroll("about");
    this.dataAboutProduct();
    this.dataAboutInfrastructure();
    this.detailAbout();
    this.dataHomea();
  },
  computed: {
    ...mapGetters(["subActive", "locale"]),
  },
  methods: {
    checkScroll(elem) {
      if (this.subActive >= 0) {
        let ref = elem + this.subActive;
        let el = this.$refs[ref];
        if (this.subActive >= 0) {
          this.scrollEl(el, "");
        }
      }
    },
    scrollEl(el, pointV) {
      console.log(el);
      let point = pointV ? pointV : "start";
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: point,
        });
      }
    },
    openProject(index) {
      this.activeProject = index;
      if (index == 1) {
        this.pictureProject = require("../assets/images/project1.jpg");
      } else if (index == 2) {
        this.pictureProject = require("../assets/images/project2.jpg");
      } else {
        this.pictureProject = require("../assets/images/bg26.png");
      }
    },
    setCareer(item, index) {
      this.imgCareer = item.image;
      this.activeCareer = index;
    },
    async dataAboutProduct() {
      await AboutService.getProductAbout()
        .then((resp) => {
          this.dataNew = resp.data.data;
          this.dataProject = this.dataNew.blocks;
        })
        .catch(() => { });
    },
    async dataAboutInfrastructure() {
      await AboutService.dataAboutInfrastructure()
        .then((resp) => {
          this.dataCloud = resp.data.data.blocks;
        })
        .catch(() => { });
    },
    async detailAbout() {
      await AboutService.detailAbout()
        .then((resp) => {
          console.log(resp.data.data);
          this.dataSlideA = resp?.data?.data?.imagesBanner;
          this.dataB.textLeft =
            resp?.data?.data?.banner[this.locale]?.text_left;
          this.dataB.textRight =
            resp?.data?.data?.banner[this.locale]?.text_right;
          this.dataB.content = resp?.data?.data?.banner[this.locale]?.content;
          this.dataSlideA.forEach((element) => {
            return element.image.replaceAll(" ", "%20");
          });
          console.log(this.dataSlideA);
          this.contentBanner = resp.data?.data?.banner;
        })
        .catch(() => { });
    },
    async dataHomea() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
          this.category = resp.data.data['menu::industrial'][this.locale];
        })
        .catch(() => {});
    },
  },
};
</script>

