import http from "@/http/http-common";
// const resource = "/api/frontend";
class WhyService {
    dataInverstment() {
        return http.get(`/why-choose/3?type=4`);
    }
    detailWhyChoose() {
        return http.get(`/detail-static-page/about/3?type=4`);
    }
}

export default new WhyService();
