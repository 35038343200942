import http from "@/http/http-common";
// const resource = "/api/frontend";
class AboutService {
    getProductAbout() {
        return http.get(`/about/2?type=2`);
    }
    dataAboutInfrastructure() {
        return http.get(`/about/2?type=3`);
    }
    detailAbout() {
        return http.get(`/detail-static-page/about/2?type=3`);
    }
}

export default new AboutService();
